export const MENU_LIST = [
  {
    text: 'Home',
    url: '/'
  },
  {
    text: 'Services',
    url: '/services'
  },
  {
    text: 'About',
    url: '/about'
  },
  {
    text: 'Contact',
    url: '/contact'
  }
  // {
  //   text: 'Portfolio',
  //   url: '/portfolio'
  // },
];

export const FOOTER_MENU = [
  {
    text: 'Home',
    url: '/'
  },
  {
    text: 'Services',
    url: '/services'
  },
  {
    text: 'About',
    url: '/about'
  },
  // {
  //   text: 'Blog',
  //   url: '/blog'
  // },
  {
    text: 'Contact',
    url: '/contact'
  }
  ,
  {
    text: 'Privacy Policy',
    url: '/about/#policy'
  },
  {
    text: 'Partnership Article',
    url: '/partnership-with-blackSmith'
  }
];

export const ASIDE_MENU = [
  {
    id: 'Healthcare',
    text: 'Healthcare'
  },
  {
    id: 'Saas',
    text: 'Saas'
  },
  {
    id: 'Finance',
    text: 'Finance'
  },
  {
    id: 'E-Commerce',
    text: 'E-Commerce'
  },
  {
    id: 'Real Estate',
    text: 'Real Estate'
  },
  {
    id: 'Education',
    text: 'Education'
  },
  {
    id: 'Travel & Tourism',
    text: 'Travel & Tourism'
  },
  {
    id: 'Insurance',
    text: 'Insurance'
  },
  {
    id: 'On-Demand Services',
    text: 'On-Demand Services'
  }

];

export const TECH_MENU = [
  {
    text: 'Frontend',
    url: '/UI/UX Design'
  },
  {
    text: 'Backend',
    url: '/about'
  },
  {
    text: 'Databases',
    url: '/services'
  },
  {
    text: 'CMS',
    url: '/blog'
  },
  {
    text: 'Mobile Application',
    url: '/contact'
  },
  {
    text: 'UI/UX',
    url: '/contact'
  }
];
